import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './MainHome.css';
import {Provider} from 'react-redux';
import BusinessIntroPage from "./BusinessIntroPage";
import JoinUsFrame from "./JoinUsFrame";
import AdminFrame from "./AdminFrame";




function App() {

    /**
     * pc , mobile check
     */
        // 모바일 여부
    let isMobile = false;

    // PC 환경
    const filter = "win16|win32|win64|mac|macintel";
    const varUA = navigator.userAgent.toLowerCase();
    if (navigator.platform) {
        isMobile = filter.indexOf(navigator.platform.toLowerCase()) < 0;
        if(isMobile) {
            console.log('mobile here');
            console.log(filter);
            window.location.href = 'https://sbc.smartbc.kr/ssbc/business/splash.php';
            // window.location.href='sbc.smartbc.kr/ssbc/business/splash.php';
            //개인용 pc버전하게 되면 param으로 구분해서 route 추가하기
            // let param = document.location.href.split('/');
            // console.log('param',param);
            // console.log('https://sbc.smartbc.kr/ssbc/'+param[3]+'/splash.php');
            // window.location.href = 'https://sbc.smartbc.kr/ssbc/'+param[3]+'/splash.php';

        }else{
            console.log('web here');
            console.log(navigator.platform);
        }
    }

    return (
            <Router>
                <Switch>
                    {/*회원 가입*/}
                    <Route exact path={'/JoinUs'} component={JoinUsFrame}/>
                    {/*메인*/}
                    <Route exact path={`/`} component={BusinessIntroPage}/>
                    {/*어드민*/}
                    <Route exact path={`/Admin`} component={AdminFrame}/>
                </Switch>
            </Router>
    );
}

export default App;
